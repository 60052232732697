import getAncestorWith from './getAncestorWith';

const handleFormErrors = (formElement, errors) => {
    Object.keys(errors).forEach((key) => { // eslint-disable-line
        if (!errors.hasOwnProperty(key) || !key) { // eslint-disable-line
            return;
        }

        const messages = errors[key];
        const formError = document.createElement('div');
        formError.classList.add('a-formError');
        const errorsList = document.createElement('ul');
        formError.appendChild(errorsList);

        for (let messageIndex = 0; messageIndex < messages.length; messageIndex++) { // eslint-disable-line
            const message = messages[messageIndex];
            const listItem = document.createElement('li');
            listItem.appendChild(document.createTextNode(message));
            errorsList.appendChild(listItem);
        }

        const inputList = formElement.querySelectorAll(`*[name=${key}], *[name='${key}[]']`); // eslint-disable-line
        for (let inputIndex = 0; inputIndex < inputList.length; inputIndex++) {  // eslint-disable-line
            const input = inputList[inputIndex];

            const parentCol = getAncestorWith('.o-freeform__col', input);
            parentCol.classList.add('has-error');

            switch (input.tagName.toLowerCase()) {
                case 'input': {
                    let element;
                    if (input.type === 'checkbox') {
                        element = getAncestorWith('.a-checkbox', input);
                        element.classList.add('a-checkbox--errors');
                    } else if (input.type === 'radio') {
                        element = getAncestorWith('.a-radio', input);
                        element.classList.add('a-radio--errors');
                    } else if (input.type === 'file') {
                        element = getAncestorWith('.a-file', input);
                        element.classList.add('a-file--errors');
                    } else {
                        element = getAncestorWith('.a-input', input);
                        element.classList.add('a-input--errors');
                    }
                    break;
                }
                case 'select': {
                    const element = getAncestorWith('.a-select', input);
                    element.classList.add('a-select--errors');
                    break;
                }
                case 'textarea': {
                    const element = getAncestorWith('.a-textarea', input);
                    element.classList.add('a-textarea--errors');
                    break;
                }
                default: {
                    break;
                }
            }

            switch (input.tagName.toLowerCase()) {
                case 'select': {
                    if (input.parentElement.parentElement) {
                        input.parentElement.parentElement.appendChild(formError);
                    }
                    break;
                }
                default: {
                    if (input.parentElement) {
                        input.parentElement.appendChild(formError);
                    }
                    break;
                }
            }
        }
    });
};

export default handleFormErrors;
