import FontFaceObserver from 'fontfaceobserver'

// Disable if you use WebFonts
// document.documentElement.className += " wf-loaded"


const fontA = new FontFaceObserver('apercu-light-pro')
const fontB = new FontFaceObserver('apercu-light-italic-pro')
const fontC = new FontFaceObserver('apercu-bold-pro')

/*fontA.load().then(function () {
 document.documentElement.className += " wf-loaded"
 })*/

Promise.all([fontA.load(), fontB.load(), fontC.load()]).then(function () {
    document.documentElement.className += " wf-loaded"
});

