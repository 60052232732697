/*
 * Description of what this file is for
 *
 * @package  craft3
 * @author David Hellmann [david@hellmann.io]
 * rocketForm
 */

import debounce from 'lodash/debounce';
import axios from 'axios';
import handleFormErrors from '../../jsHelper/formErrors';
import getAncestorWith from '../../jsHelper/getAncestorWith';

const freeform = {
  cfg: {
    name: 'freeform',
    selectors: {
      container: '.o-freeform',
      formElement: '.o-freeform form',
      submitButtons: '.o-freeform form button[type="submit"]',
    },
    classes: {
      hidden: 'is-hidden',
      loading: 'is-loading',
    },
    el: {
      container: undefined,
      formElement: undefined,
    },
    els: {
      submitButtons: undefined,
    },
  },

  setElements() {
    this.cfg.el.container = document.querySelector(this.cfg.selectors.container);
    this.cfg.el.formElement = document.querySelector(this.cfg.selectors.formElement);
    this.cfg.els.submitButtons = document.querySelectorAll(this.cfg.selectors.submitButtons);
  },

  initForm() {
    this.generateNewCsrfToken(this.cfg.el.formElement);

    // don't show success banner
    this.cfg.el.formElement.freeform.setOption('renderSuccess', () => {});

    // don't show general error message for form
    this.cfg.el.formElement.freeform.setOption('renderFormErrors', () => {});

    // render individual field errors (atom formError)
    this.cfg.el.formElement.freeform.setOption('renderFieldErrors',
      (errors) => handleFormErrors(this.cfg.el.formElement, errors));

        // Override the way those messages are removed
        this.cfg.el.formElement.freeform.setOption('removeMessages', () => {
            this.cfg.el.formElement.querySelector('.a-formError')?.remove();
        });

    // check if redirect from submitted
    const formSubmitted = window.location.href.indexOf('?submitted') !== -1;
    if (formSubmitted) {
      this.appendSuccessBanner();
    }

        const responseTemplate = this.cfg.el.container.dataset.ajaxResponse;
        if (responseTemplate) {
          document.addEventListener('freeformAjaxSuccess', async (event) => {
            const form = event.detail.form;
            this.hideFormAndContent();
            this.endSubmitWithSuccess();

        const response = await fetch(responseTemplate);
        const template = await response.text();
        this.showResponse(template);
        this.sendSuccessEvent();
        window.setTimeout(() => this.showFormAndContent(), 1000);
      });
    } else {
      this.cfg.el.formElement.addEventListener('freeform-ajax-success', (event) => {
        const response = event.response;
        if (response.returnUrl === window.location.pathname ||
          response.returnUrl === window.location.href) {
          window.location.href = `${response.returnUrl}?submitted`;
        } else {
          window.location.href = response.returnUrl;
        }
      });
    }

    this.cfg.el.formElement.addEventListener('freeform-ajax-error', () => {
      this.endSubmitWithFailure();
    });
  },

  initEventListeners() {
    this.cfg.el.formElement.addEventListener('submit', () => {
      this.startSubmit();
    });

    [...this.cfg.el.formElement.elements].forEach((field) => {
      field.addEventListener('input', debounce((evt) => {
        const { target } = evt;

        const parentCol = getAncestorWith('.o-freeform__col', target);
        parentCol.classList.remove('has-error');

        switch (target.tagName.toLowerCase()) {
          case 'input': {
            let element;
            if (target.type === 'checkbox') {
              element = getAncestorWith('.a-checkbox', target);
              element.classList.remove('a-checkbox--errors');
            } else if (target.type === 'radio') {
              element = getAncestorWith('.a-radio', target);
              element.classList.add('a-radio--errors');
            } else if (target.type === 'file') {
              element = getAncestorWith('.a-file', target);
              element.classList.add('a-file--errors');
            } else {
              element = getAncestorWith('.a-input', target);
              element.classList.remove('a-input--errors');
            }
            element.querySelector('.a-formError')?.remove();
            break;
          }
          case 'select': {
            const element = getAncestorWith('.a-select', target);
            element.classList.remove('a-select--errors');
            element.querySelectorAll('.a-formError').remove();
            break;
          }
          case 'textarea': {
            const element = getAncestorWith('.a-textarea', target);
            element.classList.remove('a-textarea--errors');
            element.querySelectorAll('.a-formError').remove();
            break;
          }
          default: {
            break;
          }
        }
      }, 200));
    });
  },

  startSubmit() {
    this.cfg.els.submitButtons.forEach(button => {
      button.classList.add(this.cfg.classes.loading);
    });
  },

  sendSuccessEvent() {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('formSentSuccessful', true, true);
    event.eventName = 'formSentSuccessful';
    this.cfg.el.formElement.dispatchEvent(event);
  },

  endSubmitWithFailure() {
    this.cfg.els.submitButtons.forEach(button => {
      button.classList.remove(this.cfg.classes.loading);
    });
  },

  endSubmitWithSuccess() {
    this.cfg.els.submitButtons.forEach(button => {
      button.classList.remove(this.cfg.classes.loading);
    });
  },

  hideFormAndContent() {
    this.cfg.el.container.classList.add(this.cfg.classes.hidden);
  },

  showFormAndContent() {
    this.cfg.el.container.classList.remove(this.cfg.classes.hidden);
  },

  showResponse(content) {
    this.cfg.el.container.innerHTML = content;
  },

  generateNewCsrfToken(form) {

    const csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
    if (csrfInput) {
      axios.get('/csrf').then((response) => {
        csrfInput.value = response.data;
      }).catch((error) => {
        // eslint-disable-next-line
      });
    }
  },

  appendSuccessBanner() {
    const bannerRendered = document.querySelector('.o-freeform__successBanner');

    if (!bannerRendered) {
      const successMessage = document.createElement('div');
      successMessage.classList.add('o-freeform__successBanner');
      successMessage.appendChild(document.createTextNode('Formular erfolgreich abgesendet.'));

      if (this.cfg.el.container.childNodes[0]) {
        this.cfg.el.container.insertBefore(successMessage, this.cfg.el.container.childNodes[0]);
      }
    }
  },

  init() {
    (() => {
      this.setElements();
      if (this.cfg.el.formElement && this.cfg.el.formElement.freeform) {
        this.initEventListeners();
        this.initForm();
      }
    })();
  }
};

export default freeform;
