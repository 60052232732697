/**
 * quoteSlider
 */
import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

const quoteSlider = {
  cfg: {
    slider: '.js-quoteSlider',
    sliderOptions: {
      modules: [Navigation],
      speed: 500,
      loop: false,
      grabCursor: true,
      autoHeight: true,
      noSwipingClass: 'no-swiping',

      // Updated Arrows configuration (use navigation instead of nextButton/prevButton)
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      
      // Slides Grid
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
  },

  init() {
    const sliders = document.querySelectorAll(this.cfg.slider);
    if (sliders.length > 0) {
      sliders.forEach((slider) => {
        const swiper = new Swiper(slider, this.cfg.sliderOptions);
        
        swiper.update();
        
        setTimeout(() => {
          swiper.update();
        }, 1000);
      });
    }
  },
};

export default quoteSlider;
