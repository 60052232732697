//  --------------------------------------------------------
//  SmoothScroll
//  https://github.com/cferdinandi/smooth-scroll
//  --------------------------------------------------------

import SmoothScroll from 'smooth-scroll';

window.addEventListener('load', () => {
    const smoothScroll = new SmoothScroll('[data-scroll]', {
        speed: 250,
        easing: 'easeInOutCubic',
    });


    if (window.location.hash) {
        setTimeout(() => {
            // Get the anchor
            const anchor = document.querySelector(window.location.hash);

            if (smoothScroll.animateScroll) {
                smoothScroll.animateScroll(anchor);
            }
        }, 250);
    }
}, false);
