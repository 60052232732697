/**
 * stickyNav
 */

import { throttle } from 'lodash';
import headerStageBig from './../headerStageBig/_main';

const stickyNav = {
    root: document.querySelector('.m-stickyNav'),
    cfg: {
        body: document.getElementsByTagName('body')[0],
        classes: {
            visible: 'is-visible',
            initialized: 'is-initialized',
            scrolled: 'is-scrolled',
        }
    },
    currentPosition: 0,

    init() {
        this.bindEventListeners();
        /**
         * Timeout to add an initialized class to the sticky nav, to only display it
         * once the component has actually loaded. Otherwise, the sticky nav would be
         * shown for a short period of time before it will be hidden, which causes
         * ugly flickering on page load.
         */
        setTimeout(() => {
            this.root.classList.add(this.cfg.classes.initialized);
        }, 500);
    },

    bindEventListeners() {
        const throttledHandler = throttle(() => this.handleScroll(), 100);
        window.addEventListener('scroll', this.handleScrollListener = () => throttledHandler(), {
            capture: true,
            passive: true
        });
    },

    handleScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        this.checkNavigationStatus(scrollPosition);
    },

    checkNavigationStatus(scrollPosition) {
        if (!this.confirmUpwardsScrollAmount(scrollPosition)) {
            this.addBodyClass();
        } else {
            this.removeBodyClass();
        }
        if (this.confirmScrollUp(scrollPosition, 200) && !this.confirmUpwardsScrollAmount(scrollPosition)) {
            this.root.classList.add(this.cfg.classes.visible);
        } else {
            this.root.classList.remove(this.cfg.classes.visible);
        }
    },

    confirmScrollUp(newPos) {
        const oldPos = this.currentPosition;
        this.currentPosition = newPos;
        return oldPos > newPos;
    },

    confirmUpwardsScrollAmount(currentPos, threshold = 0) {
        const stage = headerStageBig.cfg.headerBig;
        if (stage) {
            const { height } = stage.getBoundingClientRect();
            return currentPos < Math.floor(height + threshold);
        }
        // TODO: Discuss if this should be the right default fallback if no stage is available.
        return currentPos < window.innerHeight / 2;
    },

    addBodyClass() {
        if (this.cfg.body) this.cfg.body.classList.add(this.cfg.classes.scrolled);
    },

    removeBodyClass() {
        if (this.cfg.body) this.cfg.body.classList.remove(this.cfg.classes.scrolled);
    }
};

export default stickyNav;
