/**
 * teamListItem
 */

const teamListItem = {
    cfg: {
        teamListItems: [...document.querySelectorAll('.js-teamListItem')],
        teamMoreItems: [...document.querySelectorAll('.js-triggerTeamMore')]
    },

    eventListener() {
        this.cfg.teamListItems.forEach((item) => {
            const trigger = item.querySelector('.js-toggleAbout');

            if (trigger) {
                trigger.addEventListener('click', () => {
                    this.toggleContent(item);
                });
            }
        });
    },

    eventListenerToggleMore() {
        this.cfg.teamMoreItems.forEach((item) => {
            item.addEventListener('click', () => {
                this.expandContent(item);
            });
        });
    },

    toggleContent(item) {
        const status = item.getAttribute('data-about');
        if (status === 'open') {
            item.setAttribute('data-about', 'closed');
        } else {
            item.setAttribute('data-about', 'open');
        }
    },

    expandContent(item) {
        const textWrapper = item.parentNode.parentNode;

        if (textWrapper) {
            textWrapper.classList.add('expanded');
        }
    },

    init() {
        if (this.cfg.teamListItems) {
            this.eventListener();
        }
        if (this.cfg.teamMoreItems) {
            this.eventListenerToggleMore();
        }
    }
};

export default teamListItem;
