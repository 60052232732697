/**
 * modules
 */

import accordion from '../../../templates/_templates/accordion/_main';
import eventSignupForm from '../../../templates/_modules/eventSignupForm/_main';
import file from '../../../templates/_forms/file/_main';
import imageSlider from '../../../templates/_modules/imageSlider/_main';
import quoteSlider from '../../../templates/_modules/quoteSlider/_main';
import quoteSliderWithEntries from '../../../templates/_modules/quoteSliderWithEntries/_main';
import imageGallery from '../../../templates/_modules/imageGallery/_main';
import navCategories from '../../../templates/_modules/navCategories/_main';
import navigationMain from '../../../templates/_modules/navigationMain/_main';
import recentlyHireSlider from '../../../templates/_modules/recentlyHireSlider/_main';
import contactBox from '../../../templates/_modules/contactBox/_main';
import headerStage from '../../../templates/_modules/headerStage/_main';
import headerStageBig from '../../../templates/_modules/headerStageBig/_main';
import teamListItem from '../../../templates/_modules/teamListItem/_main';
import teamList from '../../../templates/_modules/teamList/_main';
import specialCompetences from '../../../templates/_modules/specialCompetences/_main';
import freeform from '../../../templates/_modules/freeform/_main';
import stickyNav from '../../../templates/_modules/stickyNav/_main';
import blogTeaserSlideshow from '../../../templates/_modules/blogTeaserSlideshow/_main';

// Modules Array
const modulesArray = [
    // preloader,
    accordion,
    blogTeaserSlideshow,
    eventSignupForm,
    file,
    imageSlider,
    imageGallery,
    navCategories,
    navigationMain,
    recentlyHireSlider,
    contactBox,
    headerStage,
    headerStageBig,
    quoteSlider,
    quoteSliderWithEntries,
    teamListItem,
    teamList,
    specialCompetences,
    freeform,
    stickyNav,
];

const modules = {
    // Modules
    Modules() {
        if (modulesArray.length > 0 && modulesArray !== undefined) {
            modulesArray.forEach((module) => {
                module.init();
            });
        }
    },

    init() {
        this.Modules();
    }
};

export default modules;
