/**
 * headerStage
 */

const headerStage = {
    cfg: {
        headerStage: document.querySelector('.m-headerStage')
    },

    getRandomNumer() {
        return Math.floor(Math.random() * 151) + 50;
    },

    animateChars(els) {
        setTimeout(() => {
            for (let i = 0; i < els.length; i += 1) {
                setTimeout(() => {
                    els[i].classList.add('done');
                }, 75 * i);
            }
        }, 2000);
    },

    init() {
        if (this.cfg.headerStage) {
            window.setTimeout(() => {
                this.cfg.headerStage.classList.add('is-aniStart');
            }, 1100);
        }
        /*
        const charsHolder = document.querySelector('.js-stageText')
        if (charsHolder) {
            const chars = charsHolder.querySelectorAll('.js-char')
            this.animateChars(chars)
        }
        */
    }
};


export default headerStage;
