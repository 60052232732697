/**
 * Image Slider
 */

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';

const imageSlider = {
  cfg: {
    slider: '.js-imageSwiper',
    sliderOptions: {
      modules: [Navigation, Pagination],
      speed: 500,
      loop: false,
      grabCursor: true,
      noSwiping: true,

      // Pagination settings
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },

      // Navigation buttons
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // Slides Grid
      slidesPerView: 2,
      slidesPerGroup: 2,

      // Responsive settings
      breakpoints: {
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        667: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        1024: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        1280: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        1680: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
      },
    },
  },

  init() {
    const sliders = document.querySelectorAll(this.cfg.slider);
    if (sliders.length) {
      sliders.forEach((slider) => {
        const swiper = new Swiper(slider, this.cfg.sliderOptions);

        setTimeout(() => {
          swiper.update();
        }, 500);
      });
    }
  },
};

export default imageSlider;
