/**
 * teamList
 */

const teamList = {
    cfg: {
        teamListItems: [...document.querySelectorAll('.m-teamList__item.is-hidden')],
        trigger: document.querySelector('.js-triggerTeam'),
        triggerFromNav: document.querySelector('.js-triggerTeamNav')
    },

    eventListener() {
        this.cfg.trigger.addEventListener('click', () => {
            this.showContent();
            this.cfg.trigger.remove();
        });
    },

    eventListenerFromNav() {
        this.cfg.triggerFromNav.addEventListener('click', () => {
            this.showContent();
            if (this.cfg.trigger) {
                this.cfg.trigger.remove();
            }
        });
    },

    showContent() {
        this.cfg.teamListItems.forEach((item) => {
            item.classList.remove('is-hidden');
        });
    },

    init() {
        if (this.cfg.teamListItems && this.cfg.trigger) {
            this.eventListener();
        }
        if (this.cfg.teamListItems && this.cfg.triggerFromNav) {
            this.eventListenerFromNav();
        }
    }
};

export default teamList;
