/**
 * navCategories
 */

import Swiper from 'swiper';

const navCategories = {
    cfg: {
        slider: '.js-navCategories',
        sliderOptions: {
            speed: 500,
            loop: false,
            grabCursor: true,
            freeMode: true,
            noSwipingClass: 'no-swiping',
            slidesPerView: 'auto'
        }
    },

  init() {
    const sliders = [...document.querySelectorAll(this.cfg.slider)];
    if (sliders.length > 0) {
      sliders.forEach((slider) => {
        const mySwiper = new Swiper(slider, this.cfg.sliderOptions);

        mySwiper.update();

        setTimeout(() => {
          mySwiper.update();
        }, 1000);
      });
    }
  }
};

export default navCategories;
