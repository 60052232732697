/**
 * file input
 */

const file = {
    cfg: {
        fileInput: '.a-file--file',
    },

    displayFileName(element) {
        const inputElement = element.querySelector('.a-file__input');
        const textElement = element.querySelector('.a-file__fileName');
        if (inputElement && inputElement.value && textElement) {
            const splitLocationString = inputElement.value.split(/[\\/]/);
            textElement.innerHTML = splitLocationString[splitLocationString.length - 1];
        }
    },

    addInputEventListener(element) {
        element.addEventListener('change', () => {
            this.displayFileName(element);
        });
    },

    init() {
        const _fileInputs = document.querySelectorAll(this.cfg.fileInput);
        if (_fileInputs) {
            for (let i = 0; i < _fileInputs.length; i += 1) {
                this.addInputEventListener(_fileInputs[i]);
            }
        }
    }
};

export default file;
