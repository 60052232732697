/**
 * quoteSliderWithEntries
 */
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const quoteSliderWithEntries = {
  cfg: {
    slider: '.js-quoteSliderWithEntries',
    sliderOptions: {
      modules: [Navigation],
      speed: 500,
      loop: false,
      grabCursor: true,
      autoHeight: true,
      noSwipingClass: 'no-swiping',

      // Navigation settings (update from `nextButton` and `prevButton` to `navigation`)
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // Slides Grid
      slidesPerView: 1,  // Show 1 slide at a time
      slidesPerGroup: 1, // Move 1 slide at a time
    }
  },

  init() {
    const sliders = document.querySelectorAll(this.cfg.slider);
    if (sliders.length) {
      sliders.forEach((slider) => {
        const swiper = new Swiper(slider, this.cfg.sliderOptions);

        setTimeout(() => {
          swiper.update();
        }, 500);
      });
    }
  },
};

export default quoteSliderWithEntries;
