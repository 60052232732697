/*
 * recentlyHireSlider
 */
import Swiper from 'swiper';
const recentlyHireSlider = {
  cfg: {
    slider: '.js-recentlyHireSlider',
    sliderOptions: {
      speed: 1000,
      loop: true,
      grabCursor: true,
      centeredSlides: true,
      noSwipingClass: 'no-swiping',

      // Autoplay (updated for Swiper 11)
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },

      // Slides Grid
      slidesPerView: 'auto',
      slidesPerGroup: 1,
    },
  },

  init() {
    const sliders = document.querySelectorAll(this.cfg.slider);
    if (sliders.length > 0) {
      sliders.forEach((slider) => {
        const mySwiper = new Swiper(slider, this.cfg.sliderOptions);

        // Force an update after initialization
        setTimeout(() => {
          mySwiper.update();
        }, 500);
      });
    }
  },
};

export default recentlyHireSlider;
