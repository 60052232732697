/**
 * headerStageBig
 */

const headerStageBig = {
    cfg: {
        headerBig: document.querySelector('.m-headerStageBig')
    },
    init() {
        if (this.cfg.headerBig) {
            setTimeout(() => {
                this.cfg.headerBig.classList.add('is-aniStart');
            }, 300);
        }
    }
};


export default headerStageBig;
