/**
 * contactBox
 */

import debounce from 'lodash/debounce';

const contactBox = {
    cfg: {
        contactBox: '.js-mainOffice',
        targetMobile: '.js-contactTargetMobile',
        targetDesktop: '.js-contactTargetDesktop',
        status: 'desktop'
    },

    changeStatus(status) {
        this.cfg.status = status;
    },

    checkViewport() {
        const _ww = window.innerWidth;
        if (_ww < 1024) {
            this.moveContentMobile();
        } else if (_ww >= 1024) {
            this.moveContentDesktop();
        }
    },

    moveContentMobile() {
        if (this.cfg.status !== 'mobile') {
            const _contactBox = document.querySelector(this.cfg.contactBox);
            const _targetMobile = document.querySelector(this.cfg.targetMobile);

            if (_targetMobile) {
                _targetMobile.parentNode.insertBefore(_contactBox, _targetMobile);
                this.changeStatus('mobile');
            }
        }
    },

    moveContentDesktop() {
        if (this.cfg.status !== 'desktop') {
            const _contactBox = document.querySelector(this.cfg.contactBox);
            const _targetDesktop = document.querySelector(this.cfg.targetDesktop);

            if (_targetDesktop) {
                _targetDesktop.parentNode.insertBefore(_contactBox, _targetDesktop);
                this.changeStatus('desktop');
            }
        }
    },

    init() {
        const _contactBox = document.querySelector(this.cfg.contactBox);
        if (_contactBox) {
            window.addEventListener('load', () => {
                this.checkViewport();
            }, false);

            window.addEventListener('resize', debounce(this.checkViewport.bind(this), 250));
        }
    }
};

export default contactBox;
