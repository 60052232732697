/**
 * specialCompetences
 */

const specialCompetences = {
    cfg: {
        specialCompetences: document.querySelector('.m-specialCompetences__text.is-hidden'),
        trigger: document.querySelector('.js-triggerSpecialCompetences')
    },

    eventListener() {
        this.cfg.trigger.addEventListener('click', () => {
            this.cfg.specialCompetences.classList.toggle('is-hidden');
            this.cfg.trigger.classList.toggle('is-opened');
        });
    },

    init() {
        if (this.cfg.specialCompetences && this.cfg.trigger) {
            this.eventListener();
        }
    }
};

export default specialCompetences;
