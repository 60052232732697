/**
 * eventSignupForm
 */

import bowser from 'bowser';

const eventSignupForm = {
    cfg: {
        name: 'eventSignupForm'
    },

    init() {
        // Make sure first label in "Personal Data" fieldset is in two different lines
        const textFields = document.querySelectorAll('.m-eventSignupForm .form__row--plaintext');

        if (textFields.length > 0) {
            textFields.forEach((textField) => {
                const label = textField.querySelector('.form__label label');
                if (bowser.name !== 'Internet Explorer' && label.textContent.includes(' – ')) {
                    const labelParts = label.textContent.split(' – ');
                    label.textContent = labelParts[1]; // eslint-disable-line

                    const linebreak = document.createElement('br');
                    const newLabel = document.createElement('label');
                    label.parentNode.prepend(linebreak);
                    label.parentNode.prepend(newLabel);
                    newLabel.textContent = labelParts[0]; // eslint-disable-line
                }
            });

            // Don't show fieldset with additional fields
            const fieldsets = document.querySelectorAll('fieldset');
            const headlines = document.querySelectorAll('h3');

            fieldsets[fieldsets.length - 1].classList.add('hidden');
            headlines[headlines.length - 1].classList.add('hidden');
        }
    }
};

export default eventSignupForm;
