/**
 * AnchorNavigation
 */

import scrollMonitor from 'scrollmonitor';

const anchorNavigation = {
    doAnimation(trigger, stickyNavWrapper, placeholderBox) {
        const elementWatcher = scrollMonitor.create(trigger);

        elementWatcher.exitViewport(() => {
            stickyNavWrapper.classList.add('fixed');
            placeholderBox.classList.add('fixed');
        });

        elementWatcher.enterViewport(() => {
            stickyNavWrapper.classList.remove('fixed');
            placeholderBox.classList.remove('fixed');
        });
    },

    init() {
        const stickyNavWrapper = document.querySelector('.o-section__navigation--borderBottomBlue');
        const placeholderBox = document.querySelector('.m-anchorNavigation__wrapperPlaceholder');
        const trigger = document.querySelector('.m-anchorNavigation__stickyTrigger');
        if (trigger && stickyNavWrapper && placeholderBox) {
            this.doAnimation(trigger, stickyNavWrapper, placeholderBox);
        }
    }
};

export default anchorNavigation;
