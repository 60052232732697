/**
 * navigationMain
 */

import debounce from 'lodash/debounce';

const navigationMain = {
    cfg: {
        body: document.getElementsByTagName('body')[0],
        trigger: document.querySelector('.js-navigationMainTrigger'),
        wrapper: document.querySelector('.js-navigationMainWrapper'),
        breakpoint: 1024
    },

    setBodyClass() {
        if (!this.cfg.body.classList.contains('is-open--navigation')) {
            this.cfg.body.classList.add('is-open--navigation');
        } else {
            this.cfg.body.classList.remove('is-open--navigation');
        }
    },

    removeBodyClass() {
        if (window.innerWidth >= this.cfg.breakpoint) {
            this.cfg.body.classList.remove('is-open--navigation');
        }
    },

    init() {
        if (this.cfg.trigger) {
            this.cfg.trigger.addEventListener('click', () => {
                this.setBodyClass();
            });

            window.addEventListener('resize', debounce(this.removeBodyClass.bind(this), 500));
        }
    }
};

export default navigationMain;
